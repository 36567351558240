<template>
	<div class="page">
		<div class="item">
			<div class="app_user_info">
				<div class="title">{{ $dict('个人信息') }}</div>
				<div class="form">
					<el-form label-width="140px">
						<el-form-item :label="$dict('手机号')">
							<el-input v-model="app_user_info_in_page.tel" disabled></el-input>
						</el-form-item>
						<el-form-item :label="$dict('姓名')">
							<el-input v-model="app_user_info_in_page.name" clearable></el-input>
						</el-form-item>
						<el-form-item class="text-center padding-top-xl" >
							<el-button @click="app_user_sub" type="primary">{{ $dict('提交') }}</el-button>
							<el-button @click="login_pwd_edit_open" type="primary">{{ $dict('修改登录密码') }}</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<div class="item">
			<div class="truck_carrier_info">
				<div class="title">{{ $dict('车队信息') }}</div>
				<div class="form">
					<el-form label-width="140px" disabled>
						<el-form-item :label="$dict('车队名称')">
							<el-input v-model="truck_carrier_info_in_page.name"></el-input>
						</el-form-item>
						<el-form-item :label="$dict('所在地址')">
							<el-input v-model="truck_carrier_info_in_page.addr"></el-input>
						</el-form-item>
						<el-form-item :label="$dict('税号')">
							<el-input v-model="truck_carrier_info_in_page.tax_num"></el-input>
						</el-form-item>
						<el-form-item :label="$dict('联系人')">
							<el-input v-model="truck_carrier_info_in_page.link_man"></el-input>
						</el-form-item>
						<el-form-item :label="$dict('联系电话')">
							<el-input v-model="truck_carrier_info_in_page.link_tel"></el-input>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>

		<!-- 登陆密码修改 -->
		<loginPwdEditView
			:is_show="login_pwd_edit.is_show" 
			:app_user_info="login_pwd_edit.app_user_info" 
			@edited="login_pwd_edited"
		></loginPwdEditView>
	</div>
</template>

<script>
	import loginPwdEditView from './login_pwd_edit_view.vue'
	import {mapMutations,mapState} from 'vuex'
	export default {
		components:{
			loginPwdEditView
		},
		data() {
			return {

				//app用户信息
				app_user_info_in_page: {
					name: '',
					tel: '',
				},

				//车队信息
				truck_carrier_info_in_page: {
					name: '',
					addr: '',
					tax_num: '',
					link_man: '',
					link_tel: '',
				},

				//登陆密码修改
				login_pwd_edit:{
					is_show:0,
					app_user_info:{},
				},
			}
		},
		computed:{
			...mapState(['app_user_info','truck_carrier_info'])
		},
		watch:{
			app_user_info(new_data){
				this.app_user_info_set_in_page(this.app_user_info);
			},
			truck_carrier_info(new_data){
				this.truck_carrier_info_set_in_page(this.truck_carrier_info);
			},
		},
		created() {
			this.app_user_info_set_in_page(this.app_user_info);
			this.truck_carrier_info_set_in_page(this.truck_carrier_info);
		},
		methods: {

			...mapMutations(['end_of_login']),

			//提交实名认证
			app_user_sub() {

				let need_edit={}
				let need_edit_total=0

				//姓名
				if (this.app_user_info_in_page.name.trim()) {
					need_edit.name=this.app_user_info_in_page.name.trim()
					need_edit_total++
				}

				//手机号码
				// if (this.$my.check.is_tel(this.app_user_info_in_page.tel)) {
				// 	need_edit.tel=this.app_user_info_in_page.tel
				// 	need_edit_total++
				// }

				if(need_edit_total==0)return;

				//提交
				this.$my.other.confirm({
					content: '点击确定修改个人信息',
					confirm: () => {
						this.$my.net.req({
							data: {
								mod: 'app_user',
								ctr: 'app_user_edit_by_app_user',
								...need_edit,
							},
							callback: (data) => {

								//保存用户数据
								this.end_of_login({
									app_user_info:this.app_user_info_in_page,
								});

								//提示
								this.$my.other.msg({
									type: 'success',
									str: "修改成功"
								});
							}
						});
					}
				});
			},

			//登陆密码修改
			login_pwd_edit_open(){//打开
				this.login_pwd_edit.app_user_info=this.app_user_info
				this.login_pwd_edit.is_show++
			},
			login_pwd_edited(){//完成
				this.login_pwd_edit.is_show=0
			},

			//app用户数据置入页面
			app_user_info_set_in_page(app_user_info){
				this.app_user_info_in_page.name = app_user_info.name;
				this.app_user_info_in_page.tel = app_user_info.tel;
			},
			truck_carrier_info_set_in_page(truck_carrier_info){
				this.truck_carrier_info_in_page.name = truck_carrier_info.name;
				this.truck_carrier_info_in_page.addr = truck_carrier_info.addr;
				this.truck_carrier_info_in_page.tax_num = truck_carrier_info.tax_num;
				this.truck_carrier_info_in_page.link_man = truck_carrier_info.link_man;
				this.truck_carrier_info_in_page.link_tel = truck_carrier_info.link_tel;
			},
		}
	}
</script>
<style lang="scss" scoped>
	
	.truck_carrier_info {

		.title {
			font-weight: bold;
			font-size: 24px;
			color: #555;
			padding: 20px;
		}

		.form {
			width: 95%;
			margin: 0 auto;
			padding: 10px 0 20px 0;
		}
	}

	//个人认证
	.app_user_info {

		.title {
			font-weight: bold;
			font-size: 24px;
			color: #555;
			padding: 20px;
		}

		.form {
			width: 85%;
			margin: 0 auto;
			padding: 10px 0 20px 0;
		}
	}

	.page {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: space-around;
		height: 100%;

		.item {
			min-width: 400px;
			width: 32%;
			background-color: #fff;
			border-radius: 10px;
			margin-bottom: 10px;
		}
	}
</style>
